import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"
import axios from "axios"
import { Link } from "react-router-dom"


function WomenRoster() {

    const SCHEDULE_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/women-players"
    const STAFF_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/staff"
    const [players, setPlayers] = useState([])
    const [staff, setStaff] = useState([])
    const [flag, setFlag] = useState([])

    useEffect(() => {
        getPlayers()
        getStaff()
    }, [flag]);

    function getPlayers() {
        axios.get(`${SCHEDULE_API_URL}/read.php`).then((res) => {
            if (!res.data.includes('No Records Added')) {
                setPlayers(res.data)
                setFlag(true)
            }
        }, (error) => {
        })
    }
    function getStaff() {
        axios.get(`${STAFF_API_URL}/read-by-team.php`, { params: { team: "Women Team" } }).then((res) => {
            if (!res.data.includes('No Records Added')) {
                setStaff(res.data)
                setFlag(true)
            }
        }, (error) => {
        })
    }
    function calculateAge(dob) { // birthday is a date
        var years = new Date(new Date() - new Date(dob)).getFullYear() - 1970;
        return years;
    }
    function storePlayer(player){
        localStorage.setItem('player', JSON.stringify(player))
    }

    return (
        <>
            <SubHeader
                title="Men team roster"
                page="team"
            />
            <div className="sportsmagazine-main-section sportsmagazine-player-gridfull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sportsmagazine-player sportsmagazine-classic-player">
                                <ul className="row">
                                    {
                                        players.length > 0 &&
                                        players.map((player, index) =>
                                            <li key={index} className="col-md-4">
                                                <figure>
                                                    <a ><img src={`https://nsmatrixbasketball.com/nsmatrix/images/player/${player.img}`} alt="" /></a>
                                                    <figcaption>
                                                        <div className="sportsmagazine-classic-player-info">
                                                            <h4><a href="#">{`${player.first_name} ${player.last_name}`}</a></h4>
                                                            <span>#{`${player.jersey_number} | ${player.position}`}</span>
                                                            <ul className="classic-player-option">
                                                                <li> <time dateTime="2008-02-14 20:00">Height</time></li>
                                                                <li> <span>{player.height} cm</span></li>
                                                            </ul>
                                                            <ul className="classic-player-option">
                                                                <li> <time dateTime="2008-02-14 20:00">Weight</time></li>
                                                                <li> <span>{player.weight} kg</span></li>
                                                            </ul>
                                                            <ul className="classic-player-option">
                                                                <li> <time dateTime="2008-02-14 20:00">Age</time></li>
                                                                <li> <span>{calculateAge(player.DOB)}</span></li>
                                                            </ul>
                                                            <Link to="/player">
                                                                <a className="classic-redmore-btn" onClick={(e) => {storePlayer(player)}}>MORE</a>
                                                            </Link>

                                                        </div>
                                                        <div className="sportsmagazine-classic-player-text">
                                                            <h4>
                                                                <a>
                                                                    {`${player.first_name} ${player.last_name}`}
                                                                </a>
                                                            </h4>
                                                            <span>{player.jersey_number}</span>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        )
                                    }
                                </ul>
                                <hr />
                                <ul className="row">
                                    {
                                        staff.length > 0 &&
                                        staff.map((staf, index) =>
                                            <li key={index} className="col-md-4">
                                                <figure>
                                                    <a ><img src={`https://nsmatrixbasketball.com/nsmatrix/images/staff/${staf.img}`} alt="" /></a>
                                                    <figcaption>
                                                        <div className="sportsmagazine-classic-player-text">
                                                            <h4><a >{`${staf.first_name} ${staf.last_name}`}</a></h4>
                                                            <span>{staf.title}</span>
                                                        </div>
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WomenRoster