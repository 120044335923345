import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"

import axios from "axios"

function Contact(){

  const GENERAL_API_URL = `https://nsmatrixbasketball.com/nsmatrix/api/general`
  const [info, setInfo] = useState([])
  const [flag, setFlag] = useState(false)
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')

  useEffect(() => {
    getInfo()
    setInitialValues()
  },[flag]);

  function getInfo(){
    axios.get(`${GENERAL_API_URL}/read.php`).then((res) => {
        setInfo(res.data)
        setFlag(true)
    }, (error) => {
    })
}

function setInitialValues(){
  if(flag){
      setAddress(info[0]["address"])
      setEmail(info[0]["email"])
      setTel(info[0]["tel"])
  }
  
}

    return(
        <>
        <SubHeader
                title="Contact Us"
                page="Contact"
            />
        <div className="sportsmagazine-main-section sportsmagazine-contactus-full">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sportsmagazine-fancy-title-two"><h2>Location Map <span /></h2></div>
              <div className="sportsmagazine-contact-map">
                <iframe src="http://maps.google.com/maps?q=2.870409,101.8262881&z=15&output=embed" />
              </div>
              <div className="sportsmagazine-fancy-title-two"><h2>Contact Us At <span /></h2></div>
              <div className="sportsmagazine-contactus-info">
                <ul className="row">
                  <li className="col-md-3">
                    <i className="fa fa-phone" />
                    <div className="sportsmagazine-contact-text">
                      <p>{tel}&nbsp;</p>
                      <br></br>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fa fa-map-marker" />
                    <div className="sportsmagazine-contact-text">
                      <p>{address}</p>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fa fa-envelope" />
                    <div className="sportsmagazine-contact-text">
                      <a href={`mailto:${email}`}>{email}&nbsp;</a>
                    </div>
                  </li>
                  <li className="col-md-3">
                    <i className="fa fa-fax" />
                    <div className="sportsmagazine-contact-text">
                      <p> {tel}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12"><div className="sportsmagazine-fancy-title-two"><h2>Contact Form <span /></h2></div></div>
            <div className="col-md-9">
              <div className="sportsmagazine-contact-form">
                <form>
                  <ul>
                    <li>
                      <label>Name:</label>
                      <p>
                        <input defaultValue="Type here" onBlur="if(this.value == '') { this.value ='Type here'; }" onFucos="if(this.value =='Type here') { this.value = ''; }" type="text" />
                        <span><i className="fa fa-user" /></span>
                      </p>
                    </li>
                    <li>
                      <label>Email:</label>
                      <p>
                        <input defaultValue="Type here" onBlur="if(this.value == '') { this.value ='Type here'; }" onFucos="if(this.value =='Type here') { this.value = ''; }" type="text" />
                        <span><i className="fa fa-envelope" /></span>
                      </p>
                    </li>
                    <li className="full-input">
                      <label>Message:</label>
                      <p>
                        <textarea name="u_msg" placeholder="Type here" defaultValue={""} />
                        <span><i className="fa fa-comment" /></span>
                      </p>
                    </li>
                    <li>
                      <p><label><input defaultValue="Submit" type="submit" /></label></p>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
            <div className="col-md-3">
              <ul className="sportsmagazine-contact-us-text">
                <li>
                  <h4>Send Us Message !!!</h4>
                  <p>Have any thoughts, inquiry, request or suggestions ? feel free to send us a message .</p>
                </li>
                <li>
                  <h4>Open Practices</h4>
                  <p>Spend a good time and enjoy watching the team open practices and meet the players</p>
                  <time dateTime="2008-02-14 20:00">Timings: <span>9:00am to 12:00pm</span></time>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        </>
    );
}

export default Contact;