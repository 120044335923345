import React from "react";
import SubHeader from "../components/SubHeader";

function TeamOverview(){
    return(
        <>
            <SubHeader
                title="Overview"
                page="Team"
            />
            <div className="sportsmagazine-main-section sportsmagazine-bloglarge-full">
        <div className="container">
          <div className="row">
            <div className>
              <div className="sportsmagazine-blog sportsmagazine-bloglarge">
                <ul className="row">
                  <li className="col-md-12">
                    <figure><img src={require('../assets/images/NS_Matrix_website_logo-preview.png')} alt="" /></figure>
                    <div className="sportsmagazine-bloglarge-text">
                      <p>
                        The Negeri Sembilan (NS) Matrix basketball team was established in 2015 and is owned by the Matrix Holding Concept Bhd. It was a frequent participant of the Agong Cup. With its roster having players from the Malaysian national team, they are a four-time Agong Cup champions. The club also has a women's team.
                        NS Matrix is among the six teams of the inaugural season of the Major Basketball League (MBL) of Malaysia in 2022.
                        They also joined the ASEAN Basketball League and made their debut in the 2023 season.
                      </p>
                      <p>
                        NS (Negeri Sembilan) Matrix is one of the well-known team in Malaysia known for their numerous champion title in the Agong Cup. Despite only forming in 2015, they are currently the defending champions for the 58th and 59th season of the Agong Cup (2016 and 2017).
                        Their professional team (in Agong Cup) consists of a few national players such as Kuek Tian Yuan, Wong Yi Hou, Ivan Yeo, Teo Kok Hou and Ting Chun Hong – these players also compete in the ASEAN Basketball League (ABL).
                        Team owner and founder of Matrix Holding Concept Bhd., Dato Lee Tian Hock believes focus is the key to success. He ensures that his players are well taken care of, mentally and physically outside of the court so they can focus on training for the games.
                        He wants to take the game to new heights in Malaysia, where there are different division leagues for players of various skill levels to compete then advance to the higher levels.
                        With the inaugural of Malaysia Pro League (MPL) and its Development League, the team is able to gauge their players’ skill level and develop them even further by competition.
                        Among their roster are two players who are being scouted to be in the national team – Liew Wei Yuong and Tan Chee Way, aged 22 and 17 respectively
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sportsmagazine-player sportsmagazine-player-list">
              <ul className="row">
              <li className="col-md-6">
                  <figure><img src={require('../assets/extra-images/Dato.jpeg')} alt="" /></figure>
                  <div className="sportsmagazine-player-list-text">
                    <a className="sportsmagazine-forword-btn" />
                    <h5><a>Dato' Seri Lee Tian Hock</a></h5>
                    <p>Negeri Sembilan Basketball Association Sponsor, Permanent Honorary Advisor MATRIX CONCEPT HOLDINGS BERHAD- Founder &amp; Group Executive Deputy chairman</p>
                    <ul className="sportsmagazine-player-social">
                      <li><a href="https://www.facebook.com/" className="icon-facebook2" /></li>
                      <li><a href="https://plus.google.com/" className="icon-google-plus2" /></li>
                      <li><a href="https://pk.linkedin.com/" className=" icon-linkedin" /></li>
                      <li><a href="https://twitter.com/login" className="icon-social62" /></li>
                    </ul>
                  </div>
                </li>
                <li className="col-md-6">
                  <figure><img src={require('../assets/extra-images/DatoTigerLee.jpeg')} alt="" /></figure>
                  <div className="sportsmagazine-player-list-text">
                    <a className="sportsmagazine-forword-btn" />
                    <h5><a>Dato' Tiger Lee Yuen Fong</a></h5>
                    <p>Negeri Sembilan Basketball Association President , NS MATRIX DEERS Chairman </p>
                    <p> &nbsp;</p>
                    <ul className="sportsmagazine-player-social">
                      <li><a href="https://www.facebook.com/" className="icon-facebook2" /></li>
                      <li><a href="https://plus.google.com/" className="icon-google-plus2" /></li>
                      <li><a href="https://pk.linkedin.com/" className=" icon-linkedin" /></li>
                      <li><a href="https://twitter.com/login" className="icon-social62" /></li>
                    </ul>
                  </div>
                </li>
                <li className="col-md-6">
                  <figure><img src={require('../assets/extra-images/RichardLim.png')} alt="" /></figure>
                  <div className="sportsmagazine-player-list-text">
                    <a className="sportsmagazine-forword-btn" />
                    <h5><a>Richard Lim Chew Heng</a></h5>
                    <p>Negeri Sembilan Basketball Association Executive Chairman, NS MATRIX DEERS General
                      manager </p>
                    <p> &nbsp;</p>
                    <ul className="sportsmagazine-player-social">
                      <li><a href="https://www.facebook.com/" className="icon-facebook2" /></li>
                      <li><a href="https://plus.google.com/" className="icon-google-plus2" /></li>
                      <li><a href="https://pk.linkedin.com/" className=" icon-linkedin" /></li>
                      <li><a href="https://twitter.com/login" className="icon-social62" /></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        </>
    );
}

export default TeamOverview;