import React, { useEffect, useState } from "react"
import Slider from "../components/Slider"
import { NavLink } from "react-router-dom"

import axios from "axios"

function Home() {

  const SCHEDULE_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/schedule"
  const CLUB_TEAM_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/club-team"
  const EVENTS_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/events"
  const NEWS_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/news"
  const [clubTeams, setClubTeams] = useState([])
  const [nextMatch, setNextMatch] = useState({})
  const [lastResult, setLastResult] = useState({})
  const [albums, setAlbums] = useState([])
  const [news, setNews] = useState([])

  const [flag, setFlag] = useState([])

  useEffect(() => {
    getClubTeams()
    getNextMatch()
    getLastMatchResult()
    getEventAlbums()
    getNews()
  }, [flag]);

  function getClubTeams() {
    axios.get(`${CLUB_TEAM_API_URL}/read.php`).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setClubTeams(res.data)
        setFlag(true)
      }
    }, (error) => {
    })
  }

  function getLastMatchResult() {
    axios.get(`${SCHEDULE_API_URL}/last-result.php`).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setLastResult(res.data[0])
        setFlag(true)
      }
    }, (error) => {
    })
  }


  function getNextMatch() {
    axios.get(`${SCHEDULE_API_URL}/next-match.php`).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setNextMatch(res.data[0])
        setFlag(true)
      }
    }, (error) => {
    })
  }

  function getTeamNextMatch(team) {
    axios.get(`${SCHEDULE_API_URL}/team-next-match.php`, { params: { team: team } }).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setNextMatch(res.data[0])
      }
    });
  }

  function getEventAlbums() {

    axios.get(`${EVENTS_API_URL}/home-page-read.php`).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setAlbums(res.data)
      }
    });
  }
  function getNews() {

    axios.get(`${NEWS_API_URL}/home-page-read.php`).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setNews(res.data)

      }
    });
  }

  function storeAlbum(albumName) {
    localStorage.setItem('albumName', JSON.stringify(albumName))
  }
  function storeNews(news) {
    localStorage.setItem('news', JSON.stringify(news))
  }

  return (
    <>
      <Slider />

      <div className="sportsmagazine-main-content">
        {/*// Main Section \\*/}
        <div className="sportsmagazine-main-section">
          <div className="container">
            <div className="row">
              {/*// Next Match \\*/}
              <div className="col-md-12">
                <div className="sportsmagazine-fixture-wrap">
                  <div className="sportsmagazine-teams-match">
                    <select onChange={(e) => { getTeamNextMatch(e.target.value) }}>
                      <option>SELECT TEAM</option>
                      {
                        clubTeams.length > 0 &&
                        clubTeams.map((team, index) => (
                          <option key={index}>{team.name}</option>
                        ))
                      }
                    </select>
                    <p></p>
                    <div className="sportsmagazine-first-team">
                      <div className="sportsmagazine-first-team-info">
                        <h6>{nextMatch.first_team}</h6>
                      </div>
                    </div>
                    <div className="sportsmagazine-match-view">
                      <h5>Next Match</h5>
                      <span>VS</span>
                    </div>
                    <div className="sportsmagazine-second-team">
                      <div className="sportsmagazine-second-team-info">
                        <h6>{nextMatch.second_team}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="sportsmagazine-buy-ticket">
                    <div className="sportsmagazine-buy-ticket-text">
                      <h5>{nextMatch.arena}</h5>
                      <time dateTime="2008-02-14 20:00">{nextMatch.date} <span>@ {nextMatch.time}</span></time>
                      <div className="text-center">
                        <h6><b>{nextMatch.competition}</b></h6>
                      </div>
                    </div>
                    <a className="ticket-buy-btn">
                      <NavLink to="/schedule" exact
                        activeClassName="selected">
                        <span style={{ color: '#fff' }}>See Full Schedule</span>
                      </NavLink>
                    </a>
                  </div>
                </div>
              </div>
              {/*// Next Match \\*/}
              {/*// Content \\*/}
              <div className="col-md-8">
                {/*// Latest Match Result \\*/}


                <div className="sportsmagazine-match-result">
                  <div className="sportsmagazine-match-title">
                    <h4>Latest Match Result</h4>

                  </div>
                  <ul>
                    <li>
                      <img src={`https://nsmatrixbasketball.com/nsmatrix/images/team/${lastResult.first_team_img}`} width={28} height={40} alt="" />
                      <h4><a href="fixture-detail.html">{lastResult.first_team}</a></h4>
                    </li>
                    <li className="sportsmagazine-match-score">
                      <span>{lastResult.date}</span>
                      <h5>FINAL SCORE</h5>
                      <p><strong className="sportsmagazine-color">{lastResult.first_team_result}</strong> <small>:</small> {lastResult.second_team_result}</p>
                    </li>
                    <li>
                      <img src={`https://nsmatrixbasketball.com/nsmatrix/images/team/${lastResult.second_team_img}`} width={28} height={40} alt="" />
                      <h4><a href="fixture-detail.html">{lastResult.second_team}</a></h4>
                    </li>
                    <li></li>
                  </ul>
                </div>

                {/*// Latest Match Result \\*/}
                {/*// Advertisement \\*/}
                <figure className="sportsmagazine-add-banner">
                  <img src={require('../assets/extra-images/add-thumb-1.jpg')} alt="" />
                  <span className="sportsmagazine-add-transparent" />
                  <figcaption>
                    <div className="sportsmagazine-addbanner-caption">
                      <h2>Take a look at the brand New Uniforms for next season</h2>
                      <a href="https://www.hoopsstation.com/ns-matrix" target="_blank" className="sportsmagazine-banner-btn">SHOP <span /></a>
                    </div>
                  </figcaption>
                </figure>
                {/*// Advertisement \\*/}
                {/*// Fancy Title \\*/} <div className="sportsmagazine-fancy-title"><h2>Club Media</h2></div> {/*// Fancy Title \\*/}
                <div className="sportsmagazine-gallery sportsmagazine-fixture-gallery">
                  <ul>
                    {
                      albums.length > 0 &&
                      albums.map((album, index) => (
                        index < 8 &&
                        <li key={index}>
                          <figure>
                            <a data-fancybox-group="group" href={`https://nsmatrixbasketball.com/nsmatrix/images/event/${album.cover}`} className="fancybox"><img src={`https://nsmatrixbasketball.com/nsmatrix/images/event/${album.cover}`} alt="" /><i className="icon-signs23" /></a>
                            <span>{album.photo_count} Photos</span>
                            <figcaption>
                              <h6>
                                <NavLink to="/gallery">
                                  <a onClick={(e) => { storeAlbum(album.event_name) }}>{album.event_name}</a>
                                </NavLink>
                              </h6>

                              <time >{album.event_date}</time>
                            </figcaption>
                          </figure>
                        </li>
                      ))
                    }

                    <a className="ticket-buy-btn">
                      <NavLink to="/media" exact
                        activeClassName="selected">
                        <span style={{ color: '#fff' }}>See All Media ></span>
                      </NavLink>
                    </a>
                  </ul>
                </div>
              </div>
              {/*// Content \\*/}
              {/*// SideBaar \\*/}
              <aside className="col-md-4">
                {/*// Widget TeamRanking \\*/}
                <div className="widget widget_team_ranking">
                  <div className="sportsmagazine-fancy-title"><h2>Standings <small>AGONG Cup Championship (MEN)</small></h2></div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>1st</li>
                      <li> <div className="text-center"><span>NEGERI SEMBILAN</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>2nd</li>
                      <li> <div className="text-center"><span>JOHOR</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>3rd</li>
                      <li> <div className="text-center"><span>SARAWWAK A</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>4th</li>
                      <li> <div className="text-center"><span>PERAK</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>5th</li>
                      <li> <div className="text-center"><span>PENANG 2</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>6th</li>
                      <li> <div className="text-center"><span>PENANG 1</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>7th</li>
                      <li> <div className="text-center"><span>SELANGOR</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>8th</li>
                      <li> <div className="text-center"><span>KELANTAN</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>9th</li>
                      <li> <div className="text-center"><span>SARAWAK B</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>10th</li>
                      <li> <div className="text-center"><span>PDRM</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>11th</li>
                      <li> <div className="text-center"><span>MABA SELECTION</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                <div className="widget widget_team_ranking">
                  <div className="sportsmagazine-fancy-title"><h2>Standings <small>AGONG Cup Championship (WOMEN)</small></h2></div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>1st</li>
                      <li> <div className="text-center"><span>NEGERI SEMBILAN</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>2nd</li>
                      <li> <div className="text-center"><span>JOHOR</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>3rd</li>
                      <li> <div className="text-center"><span>SELANGOR</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>4th</li>
                      <li> <div className="text-center"><span>PUTRAJAYA</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>5th</li>
                      <li> <div className="text-center"><span>PENANG</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                  <div className="ranking-content-table">
                    <ul className="ranking-content-row">
                      <li>6th</li>
                      <li> <div className="text-center"><span>PERAK</span> </div> </li>
                      <li />
                      <li />
                    </ul>
                  </div>
                </div>
                {/*// Widget TeamRanking \\*/}
                {/*// Widget Popular News \\*/}
                <div className="widget widget_popular_news">
                  <div className="sportsmagazine-fancy-title"><h2>Popular News</h2></div>
                  <ul>
                    {
                      news.length > 0 &&
                      news.map((item, index) => (
                        index < 3 &&
                        <li key={index}>
                          <span>{`0${index + 1}`}</span>
                          <div className="popular_news_text">
                            <small>{item.news_tag}</small>
                            <NavLink to="/news">
                              <a onClick={(e) => { storeNews(item.news_title) }}>{item.news_title}</a>
                            </NavLink>
                            <a href="#"></a>
                            <time dateTime="">{item.news_date}</time>
                          </div>
                        </li>
                      ))
                    }
                    <a className="ticket-buy-btn">
                      <NavLink to="/all-news" exact
                        activeClassName="selected">
                        <span style={{ color: '#fff' }}>See All News > </span>
                      </NavLink>
                    </a>
                  </ul>
                </div>
                {/*// Widget Popular News \\*/}

                {/*// Widget Newsletter \\*/}
                <div className="widget widget_newsletter">
                  <div className="sportsmagazine-fancy-title"><h2>Our Newsletter</h2></div>
                  <form>
                    <label>Subscribe Now</label>
                    <p>Sign up to receive email updates on games, announcements, news, articles and more</p>
                    <input type="text" defaultValue="Your Name" onBlur="if(this.value == '') { this.value ='Your Name'; }" onFucos="if(this.value =='Your Name') { this.value = ''; }" />
                    <input type="text" defaultValue="Enter Your email" onBlur="if(this.value == '') { this.value ='Enter Your email'; }" onFucos="if(this.value =='Enter Your email') { this.value = ''; }" />
                    <label className="widget_newsletter_btn"><input type="submit" defaultValue="Subscribe" /></label>
                  </form>
                </div>
                {/*// Widget Newsletter \\*/}
              </aside>
              {/*// SideBaar \\*/}
            </div>
          </div>
        </div>
        {/*// Main Section \\*/}
      </div>
    </>
  );
}

export default Home;