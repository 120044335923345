import React, { useEffect, useState } from "react"
import axios from "axios";

function Footer(){

  const GENERAL_API_URL = `https://nsmatrixbasketball.com/nsmatrix/api/general`
  const [info, setInfo] = useState([])
  const [fbUrl, setFbUrl] = useState('')
  const [igUrl, setIgUrl] = useState('')
  const [twitterUrl, setTwitterUrl] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    getInfo()
    setInitialValues()
  },[flag]);

  function getInfo(){
    axios.get(`${GENERAL_API_URL}/read.php`).then((res) => {
        setInfo(res.data)
        setFlag(true)
    }, (error) => {
    })
}

function setInitialValues(){
  if(flag){
      setFbUrl(info[0]["fb_url"])
      setIgUrl(info[0]["ig_url"])
      setTwitterUrl(info[0]["twitter_url"]) 
      setAddress(info[0]["address"])
      setEmail(info[0]["email"])
      setTel(info[0]["tel"])
  }
  
}

    return(

        <footer id="sportsmagazine-footer" className="sportsmagazine-footer-one">
        {/*// Footer Widget \\*/}
        <div className="sportsmagazine-footer-widget">
          <div className="container">
            <div className="row">
              {/*// Widget Contact Info \\*/}
              <aside className="col-md-4 widget widget_contact_info">
                <a className="footer-logo"><img src={require('../assets/images/NS_Matrix_website_logo-preview.png')} height={120} width={120} alt="" /></a>
                <p>The Negeri Sembilan (NS) Matrix basketball team was established in 2015 and is owned by the Matrix Holding Concept Bhd.</p>
                <ul className="sportsmagazine-social-network">
                  <li><a href={fbUrl} className="sportsmagazine-colorhover fa fa-facebook-official" /></li>
                  <li><a href="https://twitter.com/login" className="sportsmagazine-colorhover fa fa-twitter-square" /></li>
                  <li><a href={igUrl} className="sportsmagazine-colorhover fa fa-instagram" /></li>
                </ul>
                <ul className="sportsmagazine-info-list">
                  <li><i className="fa fa-map-marker" /> <span>{address}</span></li>
                  <li><i className="fa fa-phone" /> <span>{tel}</span></li>
                  <li><i className="fa fa-envelope" /> <span><a href={`mailto:${email}`}>{email}</a></span></li>
                </ul>
              </aside>
              {/*// Widget Contact Info \\*/}
              {/*// Widget Twitter \\*/}
              <aside className="col-md-4 widget widget_twitter">
                <div className=""><h2></h2></div>
                {/* <ul>
                  <li>
                    <i className="fa fa-twitter" /> <span>@basketball</span>
                    <p>Sed gravida, urna quis tempus sollicitudin, tellus urnasuscipit nisl, id rhoncus ligula elit condimentum <a href="#">https://t.co/XHrPAM4ANv</a></p>
                    <time dateTime="2008-02-14 20:00"><i className="fa fa-clock-o" /> 2 Hours Ago</time>
                  </li>
                  <li>
                    <i className="fa fa-twitter" /> <span>@football</span>
                    <p>Sed gravida, urna quis tempus sollicitudin, tellus urnasuscipit nisl, id rhoncus ligula elit condimentum <a href="#">https://t.co/XHrPAM4ANv</a></p>
                    <time dateTime="2008-02-14 20:00"><i className="fa fa-clock-o" /> 4 Hours Ago</time>
                  </li>
                </ul> */}
              </aside>
              {/*// Widget Twitter \\*/}
              {/*// Widget Gallery \\*/}
              <aside className="col-md-4 widget widget_gallery">
                <div className="footer-widget-title"><h2>Flicker Photos</h2></div>
                <ul>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy1.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy1.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy2.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy2.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy3.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy3.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy4.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy4.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy5.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy5.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy6.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy6.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy7.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy7.jpg')}  alt="" /></a></li>
                  <li><a data-fancybox-group="group" src={require('../assets/extra-images/academy8.jpg')}  className="fancybox"><img src={require('../assets/extra-images/academy8.jpg')}  alt="" /></a></li>
                </ul>
              </aside>
              {/*// Widget Gallery \\*/}
            </div>
          </div>
          <a href="#" className="sportsmagazine-back-top"><i className="fa fa-angle-up" /></a>
        </div>
        {/*// Footer Widget \\*/} 
        {/*// CopyRight \\*/}
        <div className="sportsmagazine-copyright">
          <div className="container">
            <div className="row">
              <aside className="col-md-6 sportsmagazine-copyright-left"><p /></aside>
              <aside className="col-md-6 sportsmagazine-copyright-right">
                <ul className="sportsmagazine-copyright-link">
                  <li className="sportsmagazine-colorhover">Terms And Condition</li>
                  <li className="sportsmagazine-colorhover">Privacy Policy</li>
                </ul>
              </aside>
            </div>
          </div>
        </div>
        {/*// CopyRight \\*/}
        
      </footer>

    );
}

export default Footer;