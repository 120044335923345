import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"
import { NavLink } from "react-router-dom"

import axios from "axios"

function Media() {

    const EVENTS_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/events"
    const [albums, setAlbums] = useState([])
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        getEventAlbums()
    }, [flag]);

    function getEventAlbums() {

        axios.get(`${EVENTS_API_URL}/home-page-read.php`).then((res) => {
            if (!res.data.includes('No Records Added')) {
                setAlbums(res.data)
            }
        });
    }

    function storeAlbum(albumName) {
        localStorage.setItem('albumName', JSON.stringify(albumName))
      }

    return (
        <>
            <SubHeader
                title="Club Media"
                page="Club Media"
            />
            <>
                <div className="sportsmagazine-main-section sportsmagazine-blogmodren-full">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sportsmagazine-gallery sportsmagazine-fixture-gallery">
                                    <ul className="row">
                                        {
                                            albums.length > 0 &&
                                            albums.map((album, index) => (
                                                <li key={index}>
                                                    <figure>
                                                        <a data-fancybox-group="group" href={`https://nsmatrixbasketball.com/nsmatrix/images/event/${album.cover}`} className="fancybox"><img src={`https://nsmatrixbasketball.com/nsmatrix/images/event/${album.cover}`} alt="" /><i className="icon-signs23" /></a>
                                                        <span>{album.photo_count} Photos</span>
                                                        <figcaption>
                                                            <h6>
                                                                <NavLink to="/gallery">
                                                                    <a onClick={(e) => { storeAlbum(album.event_name) }}>{album.event_name}</a>
                                                                </NavLink>
                                                            </h6>

                                                            <time >{album.event_date}</time>
                                                        </figcaption>
                                                    </figure>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/*// Main Section \\*/}
            </>
        </>
    );
}

export default Media;