import React, { useEffect, useState } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';




import axios from "axios"

function Slider() {

  const BANNER_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/banner"
  const [banner, setBanner] = useState([])
  const [flag, setFlag] = useState([])

  useEffect(() => {
    getBanners()
  }, [flag]);

  function getBanners() {
    axios.get(`${BANNER_API_URL}/read.php`).then((res) => {
      if (!res.data.includes('No Records Added')) {
        setBanner(res.data)
        setFlag(true)
      }
    }, (error) => {
    })
  }

  return (
    <>
      

      <div className="sportsmagazine-banner">
        <div className="sportsmagazine-banner-one">
        <Carousel
        autoPlay={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        
        >
           {/*// Slider \\*/}
           {
            banner.length > 0 &&
            banner.map((banner, index) =>

              <div key={index} className="sportsmagazine-banner-one-layer">
                <img src={`https://nsmatrixbasketball.com/nsmatrix/images/banner/${banner.main_img}`} alt="" />
                <span className="sportsmagazine-banner-pattren" />
                <div className="sportsmagazine-banner-caption">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="sportsmagazine-banner-wrap">
                          <h1><strong class="sportsmagazine-color">{banner.title}</strong></h1>
                          <p>{banner.banner_text}</p>
                          {/* <a href="#" className="sportsmagazine-banner-btn">Read More <span /></a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </Carousel>
         
        </div>
        {/*// Slider \\*/}
      </div>
    </>
  );
}

export default Slider;