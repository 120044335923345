import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"
import { NavLink } from "react-router-dom"

import axios from "axios"

function AllNews() {

    const NEWS_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/news"
    const [news, setNews] = useState([])
    const [flag, setFlag] = useState(false)

    useEffect(() => {
        getNews()
    }, [flag]);

    function getNews() {

        axios.get(`${NEWS_API_URL}/home-page-read.php`).then((res) => {
            if (!res.data.includes('No Records Added')) {
                setNews(res.data)

            }
        });
    }

    function storeNews(news) {
        localStorage.setItem('news', JSON.stringify(news))
    }

    return (
        <>
            <SubHeader
                title="News"
                page="News"
            />
            <>
                <div className="sportsmagazine-main-section sportsmagazine-blogmodren-full">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="sportsmagazine-blog sportsmagazine-blog-grid">
                                    <ul className="row">
                                        {
                                            news.length > 0 &&
                                            news.map((item, index) => (
                                                <li key={index} className="col-md-4">
                                                    <figure>
                                                        <NavLink to="/news">
                                                            <a onClick={(e) => { storeNews(item.news_title) }}>
                                                                <img src={`https://nsmatrixbasketball.com/nsmatrix/images/news/${item.img}`} alt={news} />
                                                            </a>
                                                        </NavLink>
                                                    </figure>
                                                    <section>
                                                        <h3>
                                                            <b>
                                                                <NavLink to="/news">
                                                                    <a onClick={(e) => { storeNews(item.news_title) }}>{item.news_title}</a>
                                                                </NavLink>
                                                            </b>
                                                        </h3>
                                                    </section>
                                                    <div className="sportsmagazine-blog-grid-options">
                                                   
                                                            {item.author}
                                                        <ul>
                                                            <li>
                                                                <i class="fa fa-calendar-o"></i>  {item.news_date}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                {/*// Pagination \\*/}
                                <div className="sportsmagazine-pagination">
                                    <ul className="page-numbers">
                                        <li>
                                            <a className="previous page-numbers" >
                                                <span aria-label="Next">
                                                    <i className="fa fa-angle-left" />
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <span className="page-numbers current">1</span>
                                        </li>



                                        <li>
                                            <a className="next page-numbers" >
                                                <span aria-label="Next">
                                                    <i className="fa fa-angle-right" />
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*// Pagination \\*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/*// Main Section \\*/}
            </>
        </>
    );
}

export default AllNews;