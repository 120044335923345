import React from "react";

function SubHeader(props){
    return(
        <div className="sportsmagazine-subheader">
        <span className="subheader-transparent" />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>{props.title}</h1>
            </div>
            <div className="col-md-12">
              <ul className="sportsmagazine-breadcrumb">
                <li><a href="/">Home</a></li>
                <li>{props.page}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SubHeader;