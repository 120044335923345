import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"
import axios from "axios"


function Schedule() {

    const SCHEDULE_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/schedule"
    const [schedule, setSchedule] = useState([])
    const [flag, setFlag] = useState([])

    useEffect(() => {
        getSchedule()
    }, [flag]);

    function getSchedule() {
        axios.get(`${SCHEDULE_API_URL}/read.php`).then((res) => {
            if (!res.data.includes('No Records Added')) {
                setSchedule(res.data)
                setFlag(true)
            }
        }, (error) => {
        })
    }

    return (
        <>
            <SubHeader
                title="Schedule"
                page="schedule"
            />
            <div className="sportsmagazine-main-section sportsmagazine-fixture-listfull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sportsmagazine-fixture sportsmagazine-fixture-list">
                                <ul className="row">
                                    {
                                        schedule.length > 0 &&
                                        schedule.map((game, index) =>
                                            <li className="col-md-12">
                                                <div className="sportsmagazine-fixture-wrap">
                                                    <div className="sportsmagazine-teams-match">
                                                        <div className="sportsmagazine-first-team">
                                                            <figure><img src alt="" /></figure>
                                                            <div className="sportsmagazine-first-team-info">
                                                                <h6>{game.first_team}</h6>
                                                                <br></br>
                                                                <h6>{game.first_team_result}</h6>
                                                                <span> </span>
                                                            </div>
                                                        </div>
                                                        <div className="sportsmagazine-match-view">
                                                            <span>VS</span>
                                                        </div>
                                                        <div className="sportsmagazine-second-team">
                                                            <figure><img src alt="" /></figure>
                                                            <div className="sportsmagazine-second-team-info">
                                                                <h6>{game.second_team}</h6>
                                                                <br></br>
                                                                <h6>{game.second_team_result}</h6>
                                                                <span> </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sportsmagazine-buy-ticket">
                                                        <div className="sportsmagazine-buy-ticket-text">
                                                            <h5>{game.arena}</h5>
                                                            <time dateTime="2008-02-14 20:00">{game.date} <span>@ {game.time}</span></time>
                                                        </div>
                                                        <a href="#" className="ticket-buy-btn">Buy Ticket</a>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Schedule