import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';



import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import TeamOverview from './pages/TeamOverview';
import Contact from './pages/Contact';
import Academy from './pages/Academy';
import Schedule from './pages/Schedule';
import MenRoster from './pages/MenRoster';
import Player from './pages/Player';
import WomenRoster from './pages/WomenRoster';
import Gallery from './pages/Gallery';
import News from './pages/News';
import AllNews from './pages/AllNews';
import Media from './pages/Media';

function App() {
  return (
    <>
      <Router>
        <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/team-overview' component={TeamOverview} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/academy' component={Academy} />
            <Route exact path='/schedule' component={Schedule} />
            <Route exact path='/men-roster' component={MenRoster} />
            <Route exact path='/women-roster' component={WomenRoster} />
            <Route exact path='/player' component={Player} />
            <Route exact path='/gallery' component={Gallery} />
            <Route exact path='/news' component={News} />
            <Route exact path='/all-news' component={AllNews} />
            <Route exact path='/media' component={Media} />
          </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;