import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"

import axios from "axios"

function Gallery() {

    const EVENT_API_URL = `https://nsmatrixbasketball.com/nsmatrix/api/events`
    const albumName = JSON.parse(localStorage.getItem('albumName'));
    const [photos, setPhotos] = useState([])
    const [flag, setFlag] = useState(false)
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState('')

    useEffect(() => {
        getPhotos()
    }, [flag]);

    function getPhotos() {
        axios.get(`${EVENT_API_URL}/event-photos.php`, { params: { eventName: albumName } }).then((res) => {
            setPhotos(res.data)
            setFlag(true)
        }, (error) => {
        })
    }



    return (
        <>
            <SubHeader
                title="Gallery"
                page="Gallery"
            />
            <div className="sportsmagazine-main-section sportsmagazine-contactus-full">
                <div className="container">
                    <div className="row"></div>
                    <div className="col-md-12">
                        <div className="sportsmagazine-fancy-title-two"><h2>{albumName}<span /></h2></div>
                        <div className="sportsmagazine-gallery grid sportsmagazine-modern-gallery">
                            <ul className="row">
                                {
                                    photos.length > 0 &&
                                    photos.map((photo, index) => (
                                        <li key={index} className="col-md-4 grid-item">
                                            <figure>
                                                <a data-fancybox-group="group" href={`https://nsmatrixbasketball.com/nsmatrix/images/event/${photo.img}`} className="fancybox"><img src={`https://nsmatrixbasketball.com/nsmatrix/images/event/${photo.img}`} alt="" /></a>
                                            </figure>
                                        </li>
                                    ))
                                }


                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Gallery;