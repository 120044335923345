import React, { useEffect, useState } from "react"
import axios from "axios"

function Player(){
    const STATS_API_URL = "https://nsmatrixbasketball.com/nsmatrix/api/stats"
    const player = JSON.parse(localStorage.getItem('player'));
    const [playerStats, setPlayerStats] = useState([])
    const [playerCompetitions, setPlayerCompetitions] = useState([])
    const [playerSelectedStats, setPlayerSelectedStats] = useState([])
    const [flag, setFlag] = useState([])
    
    

    useEffect(() => {
        
        getStats()
    },[flag])
    
    function getStats() {
        axios.get(`${STATS_API_URL}/single_record.php`, { params: { player_id: player.id } }).then((res) => {
            if (!res.data.includes('No Records Added')) {
                setPlayerStats(res.data)
                setFlag(true)
                playerComps()
                setPlayerSelectedStats(playerStats)
            }
        }, (error) => {
        })
    }

    function playerComps(){
      playerStats.map((item) => (
        playerCompetitions.indexOf(item.competition) < 0 &&
          playerCompetitions.push(item.competition)
      ))
    }

    function changeStats(comp){
      if(comp == "SELECT COMPETITION"){
        setPlayerSelectedStats(playerStats)
      }else{
        setPlayerSelectedStats(playerStats.filter(a => a.competition == comp))
      }
      
      
    }

    return(
        <div>
        <div className="sportsmagazine-main-section sportsmagazine-thumb sportsmagazine-player-thumb">
          <span className="thumb-transparent" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sportsmagazine-player-thumb-wrap">
                  <figure><img src={`https://nsmatrixbasketball.com/nsmatrix/images/player/${player.img}`} alt="" /></figure>
                  <div className="sportsmagazine-player-thumb-text">
                    <h2>{player.jersey_number}</h2>
                    <h3>{player.first_name} <br /><span> {player.last_name}</span></h3>
                    <ul className="sportsmagazine-player-info">
                      <li>
                        <h5>Nationality:</h5>
                        <span>{player.nationality}</span>
                      </li>
                      <li>
                        <h5>Date Of Birth:</h5>
                        <span>{player.DOB}</span>
                      </li>
                      <li>
                        <h5>Points Per Game:</h5>
                        <span>17.25 Avg</span>
                      </li>
                     
                      <li>
                        <h5>Height:</h5>
                        <span>{player.height} cm</span>
                      </li>
                      <li>
                        <h5>Rebounds:</h5>
                        <span>5.45 Avg</span>
                      </li>
                      <li>
                        <h5>Position:</h5>
                        <span>{player.position}</span>
                      </li>
                      <li>
                        <h5>Weight:</h5>
                        <span>{player.weight} kg</span>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*// Main Section \\*/}
        <div className="col-md-12">
        </div>
        {/*// Main Section \\*/}
        <div className="sportsmagazine-main-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="sportsmagazine-section-heading">
                  <h2>Player Stats</h2>
                </div>
                <select onChange={(e) => { changeStats(e.target.value) }}>
                      <option>SELECT COMPETITION</option>
                      {
                        playerCompetitions.length > 0 &&
                        playerCompetitions.map((item, index) => (
                          <option key={index}>{item}</option>
                        ))
                      }
                    </select>
                <table className="table table-striped table-bordered table-hover table-sm" cellPadding={5} cellSpacing={1} border={1} style={{overflowX: 'auto'}}>
                  <tbody><tr className="center">
                      <td rowSpan={2} />
                      <td rowSpan={2} />
                      <td rowSpan={2}>Min</td>
                      <td colSpan={2}>Field Goals</td>
                      <td colSpan={2}>2 Points</td>
                      <td colSpan={2}>3 Points</td>
                      <td colSpan={2}>Free Throws</td>
                      <td colSpan={3}>Rebounds</td>
                      <td rowSpan={2}>AS</td>
                      <td rowSpan={2}>TO</td>
                      <td rowSpan={2}>ST</td>
                      <td rowSpan={2}>BS</td>
                      <td colSpan={2}>Fouls</td>
                      <td rowSpan={2}>+/-</td>
                      <td rowSpan={2}>EF</td>
                      <td rowSpan={2}>PTS</td>
                      <td className="hidden" />
                    </tr>
                    <tr>
                      <td>M/A</td>
                      <td>%</td>
                      <td>M/A</td>
                      <td>%</td>
                      <td>M/A</td>
                      <td>%</td>
                      <td>M/A</td>
                      <td>%</td>
                      <td>OR</td>
                      <td>DR</td>
                      <td>TOT</td>
                      <td>PF</td>
                      <td>FD</td>
                      <td className="hidden" />
                    </tr>
                    {
                        playerSelectedStats.length > 0 ?
                        playerSelectedStats.map((stats, index) => 
                        <tr key={index}>
                        <td>{stats.game_date}</td>
                        <td><small>vs   </small>{stats.vsTeam}</td>
                        <td>{stats.min}</td>
                        <td>{stats.feild_goals_ma}</td>
                        <td>{stats.feild_golas_percent}</td>
                        <td>{stats.two_points_ma}</td>
                        <td>{stats.two_points_percent}</td>
                        <td>{stats.three_points_ma}</td>
                        <td>{stats.three_points_percent}</td>
                        <td>{stats.free_throws_ma}</td>
                        <td>{stats.free_throws_percent}</td>
                        <td>{stats.rebounds_or}</td>
                        <td>{stats.rebounds_dr}</td>
                        <td>{stats.rebounds_tot}</td>
                        <td>{stats.ass}</td>
                        <td>{stats.tos}</td>
                        <td>{stats.st}</td>
                        <td>{stats.bs}</td>
                        <td>{stats.fouls_pf}</td>
                        <td>{stats.fouls_fd}</td>
                        <td>{stats.plus_minus}</td>
                        <td>{stats.ef}</td>
                        <td>{stats.pts}</td>
                        <td className="hidden" />
                      </tr>
                        ) : <h3>No Stats Available</h3>
                    }
                    
                  </tbody></table>
                  <br></br>
                <div className="row">
                  <div className="col-md-12">
                    <div className="sportsmagazine-player-skillbar">
                      <div className="skillst">
                        <h6>Shoot Accuracy</h6>
                        <span>68%</span>
                        <div className="progressbar1" data-width={68} data-target={68} />
                      </div>
                      <div className="skillst">
                        <h6>Pass Accuracy</h6>
                        <span>85%</span>
                        <div className="progressbar1" data-width={85} data-target={85} />
                      </div>
                      <div className="skillst">
                        <h6>Total Accuracy</h6>
                        <span>72%</span>
                        <div className="progressbar1" data-width={72} data-target={72} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*// Main Section \\*/}
      </div>
    )
}
export default Player