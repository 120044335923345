import React, { useEffect, useState } from "react"
import SubHeader from "../components/SubHeader"

import axios from "axios"

function News() {

  const NEWS_API_URL = `https://nsmatrixbasketball.com/nsmatrix/api/news`
  const news = JSON.parse(localStorage.getItem('news'));
  const [newsItem, setNewsItem] = useState([])
  const [newsDate, setNewsDate] = useState('')
  const [newsText, setNewsText] = useState('')
  const [newsImg, setNewsImg] = useState('')
  const [newsTag, setNewsTag] = useState('')
  const [author, setAuthor] = useState('')
  const [flag, setFlag] = useState(false)


  useEffect(() => {
    getNews()
    setInitialValues()
  }, [flag]);

  function getNews() {
    axios.get(`${NEWS_API_URL}/single-news.php`, { params: { title: news } }).then((res) => {
      setNewsItem(res.data)
      setFlag(true)
    }, (error) => {
    })
  }

  function setInitialValues() {
    if (flag) {
      setNewsDate(newsItem[0]["news_date"])
      setNewsText(newsItem[0]["news_text"])
      setNewsTag(newsItem[0]["news_tag"])
      setAuthor(newsItem[0]["author"])
      setNewsImg(newsItem[0]["img"])
    }

  }

  return (
    <>
      <SubHeader
        title={news}
        page="News"
      />
      <div className="sportsmagazine-main-section sportsmagazine-contactus-full">
        <div className="container">
          <div className="row">
            <div className="sportsmagazine-rich-editor">
            <i class="fa fa-calendar-o"></i>  {newsDate}
              <br></br>
              {author}
              <br></br>
              <h2>
                <b>
                  {news}
                </b>
              </h2>
              <br></br>
              <p></p>
              <img src={`https://nsmatrixbasketball.com/nsmatrix/images/news/${newsImg}`} alt={news} />
              <br></br>
              <p></p>
              <p style={{ whiteSpace: "pre-line" }}>{newsText}</p>
              <div className="sportsmagazine-post-tags">
                <div className="sportsmagazine-tags">
                  <a href="#">{`#${newsTag}`}</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;